// Entry point for the build script in your package.json

// Responsive embeds
import responsiveEmbeds from "./lib/responsiveEmbeds";
responsiveEmbeds();

// Grid overlay
import gridOverlay from "./lib/gridOverlay";
gridOverlay();

// React
// import { FC } from "react";
// import * as Components from "./components";
// import ReactRailsUJS from "react_ujs";
// ReactRailsUJS.getConstructor = (className: string) =>
//   Components[className] as FC;

// Navigation
import navigation from "./features/navigation";
navigation();

import "./features/expandableBlocks";
